import React from "react"
import { Container, Row, Col } from 'react-bootstrap';
import Header from "./header"
import Footer from "./footer"
import about_img from "../images/aboutus.jpg"

const About = () => {
    return (
        <div>
            <Header  pageTitle="About Us"/>
            <section className="py-5 px-md-5 mx-xl-5">
                <Container>
                    <Row>
                        <Col md="6">
                            <h2 className="text-theme">About Us</h2>
                            <p className="my-2">The Enterprise Solutions Group (ESG) was established to meet the growing demand
                            for companies to protect against the threat of cybercrime. Our leadership team has a combined 50 years
                            of cybersecurity experience. We used the insights and knowledge gained from conducting thousands of
                            cyber and compliance audits to develop our software applications. Our goal is to develop effective,
                        efficient, and user-friendly software for cybersecurity consultants.</p>
                        </Col>
                        <Col md="6">
                            <img src={about_img} alt="About Us" className="img-fluid" />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </div>
    )
}
export default About